
.recruitment-bg{
    background-image: url(/images/sub/about/recruitment/recruitment-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.recruitment-slide{
    position: relative;
}

.recruitment-slide .swiper-container{
    border-radius: var(--radius-md) var(--radius-md) 0 0;
    border-top: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    width: 100%;
}

.recruitment-slide-wrap>p{
    border-radius: 0 0 var(--radius-md) var(--radius-md);
    overflow: hidden;
}

.recruitment-pagination{
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    z-index: 5;
}

.recruitment-btn{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid var(--border-color);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.recruitment-prev{
    left: 16px;
}

.recruitment-next{
    right: 16px;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .recruitment-btn{
        display: flex;
    }
    .recruitment-pagination{
        bottom: 30px;
    }
}
@media (min-width:1200px){
    .recruitment-prev{
    left: -24px;
    }

    .recruitment-next{
        right: -24px;
    }
}

.num-wrap{
    position: relative;
}

.num{
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #3d5063;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    line-height: 1;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .num{
        width: 45px;
        height: 45px;
    }
}
@media (min-width:1200px){
}


